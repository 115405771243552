import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import useProductLineRepositories from "repositories/product-line"
import useProductSeriesRepositories from "repositories/product-series"
import Description from "components/forms/description"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import SelectDescription from "components/forms/select-description"
import { usePage } from "contexts/page-context"

export default function Form({ data, errors, onSubmit }) {
    const _ = deepdash(lodash)

    const { configuration } = usePage()

    const { data: productSeriesSelection, isLoading: isLoadingProductSeriesSelection } = useProductSeriesRepositories({
        paginate: false
    })

    const id = data?.id

    const [name, setName] = useState(data?.name ?? null)
    const [longName, setLongName] = useState(data?.long_name ?? null)
    const [productSeries, setProductSeries] = useState(data?.product_series?.id ?? null)

    const [productSeriesObject, setProductSeriesObject] = useState()

    useEffect(() => {
        if (data) {
            setName(data.name ?? null)
            setLongName(data.long_name ?? null)
            setProductSeries(data.product_series.id ?? null)
        } else {
            if (!configuration('hierarchy').find((row) => row.key === 'product-series').value && configuration('hierarchy').find((row) => row.key === 'product-model').default_parent === 'auto' && !isLoadingProductSeriesSelection) {
                setProductSeries(productSeriesSelection[0]?.id)
            }
        }
    }, [data, isLoadingProductSeriesSelection])

    useEffect(() => {
        if (productSeries && !isLoadingProductSeriesSelection) {
            setProductSeriesObject(productSeriesSelection.find((row) => row.id === productSeries))
        }
    }, [productSeries, isLoadingProductSeriesSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            onSubmit({
                id,
                name,
                long_name: longName,
                product_series_id: productSeries,
                product_series: productSeriesObject
            })
        } else {
            toast.error("Previous action is loading")
        }
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    {configuration('hierarchy').find((row) => row.key === 'product-series').value && (
                                        <>
                                            <div>
                                                <Label htmlFor="series" value="Product Series" />
                                                <SelectDescription onChange={(value) => setProductSeries(value)} selection={productSeriesSelection} isLoading={isLoadingProductSeriesSelection} value={productSeries} keyValue={(value) => value.id} title={(value) => value.series?.name} description={(value) => `${value.product_brand?.product_type?.name} – ${value.product_brand?.brand?.name}`} placeholder="Select Product Series" error={errors.product_series_id} reverse />
                                                <ErrorMessage error={errors.product_series_id} />
                                            </div>
                                            <div></div>
                                        </>
                                    )}
                                    <div>
                                        <Label htmlFor="name" value="Name" />
                                        <Input placeholder="Input name here" onChange={(e) => setName(e.target.value)} value={name} id="name" error={errors.name} />
                                        <Description error={errors.name} />
                                    </div>
                                    <div>
                                        <Label htmlFor="long_name" value="Long Name" />
                                        <Input placeholder="Input long name here" onChange={(e) => setLongName(e.target.value)} value={longName} id="long_name" error={errors.long_name} />
                                        <Description error={errors.long_name} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}