import { createBrowserRouter, createHashRouter } from "react-router-dom"

import GuestRoute from "routes/middleware/guest"
import ProtectedRoute from "routes/middleware/protected"

import Dashboard from "pages/dashboard"
import Approve from "pages/approval/approve"
import Submitted from "pages/approval/submitted"
import Login from "pages/auth/login"
import Register from "pages/auth/register"
import NoRole from "pages/no-role"
import { show as showDashboard } from "./loader/dashboard"
import { load as loadMainData } from "./loader/main"
import { loadAutoRedirect as loadAutoRedirectApproval } from "./loader/approval"
import { load as loadApproval } from "./loader/approval"

import { show as showBrand } from "./loader/brand"
import { show as showSeries } from "./loader/series"
import { show as showAttributeGroup } from "./loader/attribute-group"
import { show as showAttribute } from "./loader/attribute"
import { show as showProductNeed } from "./loader/product-need"
import { show as showProductFamily } from "./loader/product-family"
import { show as showProductClass } from "./loader/product-class"
import { show as showProductLine } from "./loader/product-line"
import { show as showProductType } from "./loader/product-type"
import { show as showProductBrand } from "./loader/product-brand"
import { show as showProductSeries } from "./loader/product-series"
import { show as showProductModel } from "./loader/product-model"
import { show as showProductUnit } from "./loader/product-unit"
import { show as showDiscount } from "./loader/discount"
import { show as showReward } from "./loader/reward"

import { getByUser as getBranchByUser } from "./loader/branch"
import SelectBranch from "pages/select-branch"
import { lang } from "config"
import AlreadySubmitted from "pages/approval/already-submitted"
import Error from "pages/error"
import { List as BrandList, Create as CreateBrand, Edit as EditBrand } from "pages/brand"
import { List as SeriesList, Create as CreateSeries, Edit as EditSeries } from "pages/series"
import { List as AttributeList, Create as CreateAttribute, Edit as EditAttribute } from "pages/attribute"
import { List as AttributeGroupList, Create as CreateAttributeGroup, Edit as EditAttributeGroup } from "pages/attribute/group"
import { List as ProductNeedList, Create as CreateProductNeed, Edit as EditProductNeed } from "pages/product/need"
import { List as ProductFamilyList, Create as CreateProductFamily, Edit as EditProductFamily } from "pages/product/family"
import { List as ProductClassList, Create as CreateProductClass, Edit as EditProductClass } from "pages/product/class"
import { List as ProductLineList, Create as CreateProductLine, Edit as EditProductLine } from "pages/product/line"
import { List as ProductTypeList, Create as CreateProductType, Edit as EditProductType } from "pages/product/type"
import { List as ProductBrandList, Create as CreateProductBrand, Edit as EditProductBrand } from "pages/product/brand"
import { List as ProductSeriesList, Create as CreateProductSeries, Edit as EditProductSeries } from "pages/product/series"
import { List as ProductModelList, Create as CreateProductModel, Edit as EditProductModel } from "pages/product/model"
import { List as ProductUnitList, Create as CreateProductUnit, Edit as EditProductUnit } from "pages/product/unit"
import { List as ProductMapList, Create as CreateProductUnitBranchMap } from "pages/product/map"
import { List as ProductUnitExclusivityList, Create as CreateProductUnitExclusivity } from "pages/product-exclusivity"
import { List as DiscountList, Create as CreateDiscount, Edit as EditDiscount, Apply as ApplyDiscount } from "pages/discount"
import { List as RewardList, Create as CreateReward, Edit as EditReward } from "pages/reward"

import AppLayout from "layouts/app-layout"
import { Form as ProductWizardForm } from "pages/product/wizard"
import Settings from "pages/settings"

export const routeList = [
    // Root loader
    {
        loader: loadMainData,
        errorElement: <Error />,
        children: [
            // Guest
            {
                path: "/register",
                element: (
                    <GuestRoute>
                        <Register title={lang.title.sign_up} />
                    </GuestRoute>
                )
            },
            {
                path: "/login",
                element: (
                    <GuestRoute>
                        <Login title={lang.title.sign_in} />
                    </GuestRoute>
                )
            },

            // Protected
            {
                path: "/branch/select",
                element: (
                    <ProtectedRoute>
                        <SelectBranch title={lang.title.select_branch} />
                    </ProtectedRoute>
                ),
                loader: getBranchByUser
            },
            {
                path: "/approve/:number",
                key: 'approve',
                element: (
                    <ProtectedRoute needToHaveRole={false} needToSelectBranch={false}>
                        <Approve />
                    </ProtectedRoute>
                ),
                loader: loadAutoRedirectApproval
            },
            {
                path: "/approve/:number/submitted",
                element: (
                    <ProtectedRoute needToHaveRole={false} needToSelectBranch={false}>
                        <Submitted title="Approval Submitted" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/approve/:number/already",
                element: (
                    <ProtectedRoute needToHaveRole={false} needToSelectBranch={false}>
                        <AlreadySubmitted title="Approval Already Submitted" />
                    </ProtectedRoute>
                ),
                loader: loadApproval
            },
            {
                path: "/no-role",
                element: (
                    <ProtectedRoute>
                        <NoRole title="No Role Assigned" />
                    </ProtectedRoute>
                )
            },

            // Protected, App Layout
            {
                middleware: "protected",
                element: (
                    <ProtectedRoute>
                        <AppLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: "/",
                        index: true,
                        title: "Dashboard",
                        element: (
                            <Dashboard />
                        ),
                        // loader: showDashboard
                    },
                    {
                        path: "/settings",
                        title: "Settings",
                        element: (
                            <Settings />
                        )
                    },
                    {
                        path: "/brand",
                        title: "Brand",
                        element: (
                            <BrandList key="/brand" />
                        )
                    },
                    {
                        path: "/brand/create",
                        title: "Create Brand",
                        element: (
                            <CreateBrand key="/brand/create" title="Create Brand" />
                        )
                    },
                    {
                        path: "/brand/:id/edit",
                        element: (
                            <EditBrand />
                        ),
                        loader: showBrand
                    },
                    {
                        path: "/brand/series",
                        title: "Series",
                        element: (
                            <SeriesList key="/brand/series" />
                        )
                    },
                    {
                        path: "/brand/series/create",
                        title: "Create Series",
                        element: (
                            <CreateSeries key="/brand/series" />
                        )
                    },
                    {
                        path: "/brand/series/:id/edit",
                        element: (
                            <EditSeries />
                        ),
                        loader: showSeries
                    },
                    {
                        path: "/attribute",
                        title: "Attribute",
                        element: (
                            <AttributeList key="/attribute" />
                        )
                    },
                    {
                        path: "/attribute/create",
                        title: "Create Attribute",
                        element: (
                            <CreateAttribute key="/attribute/create" />
                        )
                    },
                    {
                        path: "/attribute/:id/edit",
                        element: (
                            <EditAttribute />
                        ),
                        loader: showAttribute
                    },
                    {
                        path: "/attribute/group",
                        title: "Attribute Group",
                        element: (
                            <AttributeGroupList key="/attribute/group" />
                        )
                    },
                    {
                        path: "/attribute/group/create",
                        title: "Create Attribute Group",
                        element: (
                            <CreateAttributeGroup key="/attribute/group/create" />
                        )
                    },
                    {
                        path: "/attribute/group/:id/edit",
                        title: "Create Attribute Group",
                        element: (
                            <EditAttributeGroup />
                        ),
                        loader: showAttributeGroup
                    },
                    {
                        path: "/product/need",
                        title: "Product Need",
                        element: (
                            <ProductNeedList />
                        )
                    },
                    {
                        path: "/product/need/create",
                        title: "Create Product Need",
                        element: (
                            <CreateProductNeed />
                        )
                    },
                    {
                        path: "/product/need/:id/edit",
                        element: (
                            <EditProductNeed />
                        ),
                        loader: showProductNeed
                    },
                    {
                        path: "/product/family",
                        title: "Product Family",
                        element: (
                            <ProductFamilyList />
                        )
                    },
                    {
                        path: "/product/family/create",
                        title: "Create Product Family",
                        element: (
                            <CreateProductFamily />
                        )
                    },
                    {
                        path: "/product/family/:id/edit",
                        element: (
                            <EditProductFamily />
                        ),
                        loader: showProductFamily
                    },
                    {
                        path: "/product/class",
                        title: "Product Class",
                        element: (
                            <ProductClassList />
                        )
                    },
                    {
                        path: "/product/class/create",
                        title: "Create Product Class",
                        element: (
                            <CreateProductClass />
                        )
                    },
                    {
                        path: "/product/class/:id/edit",
                        element: (
                            <EditProductClass />
                        ),
                        loader: showProductClass
                    },
                    {
                        path: "/product/line",
                        title: "Product Line",
                        element: (
                            <ProductLineList />
                        )
                    },
                    {
                        path: "/product/line/create",
                        title: "Create Product Line",
                        element: (
                            <CreateProductLine />
                        )
                    },
                    {
                        path: "/product/line/:id/edit",
                        element: (
                            <EditProductLine />
                        ),
                        loader: showProductLine
                    },
                    {
                        path: "/product/type",
                        title: "Product Type",
                        element: (
                            <ProductTypeList />
                        )
                    },
                    {
                        path: "/product/type/create",
                        title: "Create Product Type",
                        element: (
                            <CreateProductType />
                        )
                    },
                    {
                        path: "/product/type/:id/edit",
                        element: (
                            <EditProductType />
                        ),
                        loader: showProductType
                    },
                    {
                        path: "/product/brand",
                        title: "Product Brand",
                        element: (
                            <ProductBrandList />
                        )
                    },
                    {
                        path: "/product/brand/create",
                        title: "Create Product Brand",
                        element: (
                            <CreateProductBrand />
                        )
                    },
                    {
                        path: "/product/brand/:id/edit",
                        element: (
                            <EditProductBrand />
                        ),
                        loader: showProductBrand
                    },
                    {
                        path: "/product/series",
                        title: "Product Series",
                        element: (
                            <ProductSeriesList />
                        )
                    },
                    {
                        path: "/product/series/create",
                        title: "Create Product Series",
                        element: (
                            <CreateProductSeries />
                        )
                    },
                    {
                        path: "/product/series/:id/edit",
                        element: (
                            <EditProductSeries />
                        ),
                        loader: showProductSeries
                    },
                    {
                        path: "/product/model",
                        title: "Product Model",
                        element: (
                            <ProductModelList />
                        )
                    },
                    {
                        path: "/product/model/create",
                        title: "Create Product Model",
                        element: (
                            <CreateProductModel />
                        )
                    },
                    {
                        path: "/product/model/:id/edit",
                        element: (
                            <EditProductModel />
                        ),
                        loader: showProductModel
                    },
                    {
                        path: "/product/unit",
                        title: "Product Unit",
                        element: (
                            <ProductUnitList />
                        )
                    },
                    {
                        path: "/product/unit/create",
                        title: "Create Product Unit",
                        element: (
                            <CreateProductUnit />
                        )
                    },
                    {
                        path: "/product/unit/:id/edit",
                        element: (
                            <EditProductUnit />
                        ),
                        loader: showProductUnit
                    },
                    // {
                    //     path: "/product/wizard",
                    //     title: "Product Wizard",
                    //     element: (
                    //         <ProductWizardForm key="/product/wizard" title="Product Wizard" />
                    //     )
                    // },
                    {
                        path: "/product/wizard",
                        title: "Product Wizard",
                        element: (
                            <CreateProductUnit />
                        )
                    },
                    {
                        path: "/product/map",
                        title: "Product Unit Branch Map",
                        element: (
                            <ProductMapList />
                        )
                    },
                    {
                        path: "/product/map/create",
                        title: "Create Product Unit Branch Map",
                        element: (
                            <CreateProductUnitBranchMap />
                        )
                    },
                    {
                        path: "/exclusivity",
                        title: "Product Unit Exclusivity",
                        element: (
                            <ProductUnitExclusivityList />
                        )
                    },
                    {
                        path: "/exclusivity/create",
                        title: "Create Product Unit Exclusivity",
                        element: (
                            <CreateProductUnitExclusivity />
                        )
                    },
                    {
                        path: "/discount",
                        title: "Discount",
                        element: (
                            <DiscountList />
                        )
                    },
                    {
                        path: "/discount/create",
                        title: "Create Discount",
                        element: (
                            <CreateDiscount />
                        )
                    },
                    {
                        path: "/discount/apply",
                        title: "Apply Discount",
                        element: (
                            <ApplyDiscount />
                        )
                    },
                    {
                        path: "/discount/:id/edit",
                        element: (
                            <EditDiscount />
                        ),
                        loader: showDiscount
                    },
                    {
                        path: "/reward",
                        title: "Reward",
                        element: (
                            <RewardList />
                        )
                    },
                    {
                        path: "/reward/create",
                        title: "Create Reward",
                        element: (
                            <CreateReward />
                        )
                    },
                    {
                        path: "/reward/:id/edit",
                        element: (
                            <EditReward />
                        ),
                        loader: showReward
                    }
                ]
            }
        ]
    }
]

export const routes = process.env.NODE_ENV === 'development' ? createBrowserRouter(routeList) : createHashRouter(routeList)

export default routes