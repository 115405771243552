import { useEffect, useState } from "react"

import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { lang } from "config"
import Label from "components/forms/label"
import nProgress from "nprogress"
import toast from "react-hot-toast"

import SelectDescription from "components/forms/select-description"
import useDiscountRepositories from "repositories/discount"
import InputDate from "components/forms/input-date"
import moment from "moment"
import { X } from "components/icons/outline"
import Description from "components/forms/description"

import { Add as AddTarget } from "./target"
import { useNavigate } from "react-router-dom"
import { useAuth } from "contexts/auth-context"
import axios from "axios"
import { useSWRConfig } from "swr"
import { useAuditLogContext } from "contexts/audit-log-context"

export default function Form() {
    const _ = deepdash(lodash)

    const { user } = useAuth()
    const { cache } = useSWRConfig()

    const { queueAuditLog } = useAuditLogContext()

    const navigate = useNavigate()

    // Forms
    const [level, setLevel] = useState()
    const [discount, setDiscount] = useState()
    const [from, setFrom] = useState()
    const [to, setTo] = useState()
    const [target, setTarget] = useState([])

    const [discountObject, setDiscountObject] = useState()

    const [errors, setErrors] = useState({})

    const levelSelection = [
        {
            key: "type",
            label: "Product Type"
        },
        {
            key: "brand",
            label: "Product Brand"
        },
        {
            key: "series",
            label: "Product Series"
        },
        {
            key: "model",
            label: "Product Model"
        },
        {
            key: "unit",
            label: "Product Unit"
        }
    ]

    const { data: discountSelection, isLoading: isLoadingDiscountSelection } = useDiscountRepositories({
        paginate: false
    })

    useEffect(() => {
        setTarget([])
    }, [level])

    useEffect(() => {
        if (discount && !isLoadingDiscountSelection) {
            setDiscountObject(discountSelection.find((row) => row.id === discount))
        }
    }, [discount, isLoadingDiscountSelection])

    const handleAddTarget = (data) => {
        setTarget([...target, data])
    }

    const handleRemoveTarget = (index) => {
        const value = target.filter((row, i) => i !== index)

        setTarget(value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const targetData = target.map((row) => {
            return row?.id
        })

        if (!nProgress.isStarted()) {
            toast.loading("Loading", {
                id: "submit"
            })

            toast.dismiss("conflict_data")

            const url = `${process.env.REACT_APP_INVENTORY_API_URL}/discount/apply`
            const auditLogPayload = {
                level,
                discount_id: discount,
                discount: discountObject?.name,
                from,
                to,
                target: target.map((row) => {
                    return {
                        [`product_${level}_id`]: row.id,
                        [`product_${level}`]: row.name ?? (row.brand?.name ?? row.series?.name)
                    }
                }),
            }
            const formPayload = {
                level,
                target: targetData,
                discount,
                from,
                to,

                // updated_by: user?.name
            }

            axios.post(url, formPayload).then((response) => {
                // Queue audit log
                queueAuditLog(url, response.config.method.toUpperCase(), "Apply Discount Data", user?.name, auditLogPayload, null, moment().format('YYYY-MM-DD HH:mm:ss'), response.status)

                // Clear SWR cache
                cache.clear()

                // Navigate page
                navigate({ pathname: '/discount' })

                // Show toast message
                toast.success(response.data.message, {
                    id: "submit"
                })
            }).catch((error) => {
                // Assign validation message
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors)
                }

                // Show toast message
                if (error.response.status === 400) {
                    var names = error.response.data.data.map((row, i) => (i + 1) !== error.response.data.data.length ? `${row}, ` : row)

                    toast.error(names, {
                        ariaProps: {
                            superscript: "Conflict Data"
                        },
                        id: "conflict_data"
                    })
                }

                // Show toast message
                toast.error(error.response.data.message, {
                    ariaProps: {
                        superscript: error.response.status
                    },
                    id: "submit"
                })
            })
        } else {
            toast.error("Previous action is loading")
        }
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="border sm:rounded-xl">
                            <div className="px-4 py-5 space-y-8 sm:p-6">
                                {/* Set min width to zero, fix fieldset overflowing parent. */}
                                <fieldset className="min-w-0">
                                    <legend className="sr-only">Level and Target</legend>
                                    <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                        Level and Target
                                    </div>
                                    <div className="mt-4 space-y-4">
                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <Label htmlFor="level" value="Level" />
                                                <SelectDescription onChange={(value) => setLevel(value)} selection={levelSelection} isLoading={false} value={level} keyValue={(value) => value.key} title={(value) => value.label} placeholder="Select Level" error={errors.level} />
                                                <ErrorMessage error={errors.level} />
                                            </div>
                                        </div>
                                        <div>
                                            <Label value="Target" />
                                            <div className="mt-1 overflow-x-auto border border-neutral-200 rounded-xl">
                                                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                                    <thead className="bg-neutral-50 rounded-t-3xl">
                                                        <tr>
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{`Product ${level}`}</th>
                                                            {true && (
                                                                <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-neutral-200">
                                                        {target.length === 0 && (
                                                            <tr className="text-center">
                                                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    {lang.no_data}
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {target.length !== 0 && target?.map((row, index) => (
                                                            <tr key={row}>
                                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                    <div className="flex flex-col space-y-1">
                                                                        {level !== 'brand' && (<div>{level === 'series' ? row.product_brand?.brand.name : row.long_name}</div>)}
                                                                        <div className="font-medium text-neutral-900">{level === 'brand' ? row.brand?.name : (level === 'series' ? row.series?.name : row.name)}</div>
                                                                    </div>
                                                                </td>
                                                                {true && (
                                                                    <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                                        <button type="button" onClick={() => handleRemoveTarget(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                                            <X className="w-6 h-6" />
                                                                        </button>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <Description error={errors.target} />
                                        <div className="text-xs">
                                            <AddTarget data={{ level, target }} onSubmit={(data) => handleAddTarget(data)} />
                                        </div>
                                    </div>
                                </fieldset>
                                {/* Set min width to zero, fix fieldset overflowing parent. */}
                                <fieldset className="min-w-0">
                                    <legend className="sr-only">Discount</legend>
                                    <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                        Discount
                                    </div>
                                    <div className="mt-4 space-y-4">
                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <Label htmlFor="discount" value="Discount" />
                                                <SelectDescription onChange={(value) => setDiscount(value)} selection={discountSelection} isLoading={isLoadingDiscountSelection} value={discount} keyValue={(value) => value.id} title={(value) => value.name} placeholder="Select Discount" error={errors.discount} />
                                                <ErrorMessage error={errors.discount} />
                                            </div>
                                            <div></div>
                                            <div>
                                                <Label htmlFor="from" value="From" />
                                                <InputDate error={errors.from} id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-MM-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                                                <ErrorMessage error={errors.from} />
                                            </div>
                                            <div>
                                                <Label htmlFor="to" value="To" />
                                                <InputDate error={errors.to} id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-MM-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                                                <ErrorMessage error={errors.to} />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>Apply</span>
                </button>
            </div>
        </form>
    )
}