import { useEffect, useState } from 'react'
import { Modal } from 'components'
import ErrorMessage from 'components/forms/error-message'
import { lang } from 'config'
import _ from 'lodash'
import { Plus } from 'components/icons/outline'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import nProgress from 'nprogress'
import Label from 'components/forms/label'
import useAttributeGroupRepositories from 'repositories/attribute-group'
import useAttributeRepositories from 'repositories/attribute'
import SelectDescription from 'components/forms/select-description'

const Content = ({ data, onSubmit }) => {
    const [attributeGroupId, setAttributeGroupId] = useState()
    const [attributeGroup, setAttributeGroup] = useState()
    const [attributeId, setAttributeId] = useState()
    const [attribute, setAttribute] = useState()

    const [errors, setErrors] = useState({})

    const { data: attributeGroupSelection, isLoading: isLoadingAttributeGroupSelection } = useAttributeGroupRepositories({
        paginate: false
    })

    const { data: attributeSelection, isLoading: isLoadingAttributeSelection } = useAttributeRepositories({
        paginate: false,
        group: attributeGroupId
    })

    useEffect(() => {
        if (attributeGroupId && !isLoadingAttributeGroupSelection) {
            setAttributeGroup(attributeGroupSelection?.find((row) => row.id === attributeGroupId))
        }
    }, [attributeGroupId, isLoadingAttributeGroupSelection])

    useEffect(() => {
        if (attributeId && !isLoadingAttributeSelection) {
            setAttribute(attributeSelection?.find((row) => row.id === attributeId))
        }
    }, [attributeId, isLoadingAttributeSelection])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!nProgress.isStarted()) {
            nProgress.start()

            toast.loading("Loading", {
                id: "submit"
            })

            axios.post(`${process.env.REACT_APP_INVENTORY_API_URL}/product/unit/validate`, {
                type: 'attribute',
                attribute_group: attributeGroup,
                attribute_group_id: attributeGroupId,
                attribute: attribute,
                attribute_id: attributeId
            }).then((response) => {
                toast.success(response.data.message, {
                    id: "submit"
                })

                onSubmit({
                    attribute_group: attributeGroup,
                    attribute_group_id: attributeGroupId,
                    attribute: attribute,
                    attribute_id: attributeId
                })

                nProgress.done()
            }).catch((error) => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors)
                }
                toast.error(error.response.data.message, {
                    id: "submit"
                })

                console.log(error.response)

                nProgress.done()
            })
        } else {
            toast.error("Previous action is loading")
        }
    }

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="attribute_group" value="Grup Atribut" />
                        <SelectDescription onChange={(value) => setAttributeGroupId(value)} selection={attributeGroupSelection?.filter((group) => !data?.find((row) => row.attribute_group_id == group.id))} isLoading={isLoadingAttributeGroupSelection} value={attributeGroupId} keyValue={(value) => value.id} title={(value) => value.name} placeholder="Select Attribute Group" error={errors.attribute_group_id} />
                        <ErrorMessage error={errors.attribute_group_id} />
                    </div>

                    <div>
                        <Label htmlFor="attribute" value="Atribut" />
                        <SelectDescription disabled={!attributeGroupId || isLoadingAttributeSelection} onChange={(value) => setAttributeId(value)} selection={attributeSelection} isLoading={isLoadingAttributeSelection} value={attributeId} keyValue={(value) => value.id} title={(value) => value.name} placeholder="Select Attribute" error={errors.attribute_id} />
                        <ErrorMessage error={errors.attribute_id} />
                    </div>
                </div>
            </div>
            <div className="text-xs">
                <button onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.save}</span>
                </button>
            </div>
        </div>
    )
}

const Add = ({ data, onSubmit }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (data) => {
        onSubmit(data)

        closeModal()
    }

    return (
        <>
            <button type="button" onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <Plus className="w-4 h-4" stroke={1.5} />
                <span>{lang.create}</span>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.create} ${lang.data}`} content={<Content data={data} onSubmit={handleSubmit} />} />
        </>
    )
}

export default Add