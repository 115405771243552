import { Disclosure } from "@headlessui/react"
import { lang } from "config"
import { useAuth } from "contexts/auth-context"
import { AnimatePresence, motion, useAnimationControls } from "framer-motion"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// Tailwind
import resolveConfig from 'tailwindcss/resolveConfig'
import defaultTheme from 'tailwindcss/defaultTheme'
import { usePage } from "contexts/page-context"
import { AB, Box, Percentage, Reward, Trademark, ChevronRight, PackageExclusive, Settings } from "components/icons/outline"

const Sidebar = ({ isSidebarOpen }) => {
    const url = useLocation().pathname
    const { can } = useAuth()
    const { configuration } = usePage()

    const fullConfig = resolveConfig(defaultTheme)

    const sidebar = useAnimationControls()

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    // Listen to the window resize event
    useEffect(() => {
        // window.onresize = () => {
        //     setWindowSize({
        //         width: window.innerWidth,
        //         height: window.innerHeight,
        //     })
        // }

        const updateWindowSizeState = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', updateWindowSizeState)

        return () => window.removeEventListener('resize', updateWindowSizeState)
    }, [])

    useEffect(() => {
        if (windowSize.width < parseInt(fullConfig.theme.screens.lg.replace('px', ''))) {
            if (isSidebarOpen) {
                sidebar.start({
                    x: 0,
                    zIndex: 'auto'
                })
            } else {
                sidebar.start({
                    x: '-4rem',
                    zIndex: '-1'
                })
            }
        } else {
            sidebar.set({
                x: 0,
                zIndex: 'auto'
            })
        }
    }, [isSidebarOpen, windowSize])

    return (
        <motion.div animate={sidebar} transition={{ type: 'spring', damping: 20 }} className="flex flex-col w-0 max-h-screen pt-12 space-y-16 font-sans lg:w-fit">
            <div className="ml-12 text-3xl">
                {process.env.REACT_APP_NAME}
            </div>
            <nav className="w-56 max-h-full px-4 pb-8 ml-8 space-y-8 overflow-x-visible overflow-y-auto text-sm">
                <div className="space-y-2">
                    <div className="text-xs">
                        {lang.pages}
                    </div>
                    <ul>
                        <li>
                            <Link to="/" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                <div className={`${url === '/' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19 8.71l-5.333 -4.148a2.666 2.666 0 0 0 -3.274 0l-5.334 4.148a2.665 2.665 0 0 0 -1.029 2.105v7.2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-7.2c0 -.823 -.38 -1.6 -1.03 -2.105"></path>
                                        <path d="M16 15c-2.21 1.333 -5.792 1.333 -8 0"></path>
                                    </svg>
                                </div>
                                <div>
                                    {lang.title.dashboard}
                                </div>
                                {url === '/' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                            </Link>
                        </li>
                    </ul>
                </div>

                {(can('brand.view') || can('series.view') || can('attribute.view') || can('attribute-group.view') || can('product-need.view') || can('product-family.view') || can('product-class.view') || can('product-line.view') || can('product-type.view') || can('product-brand.view') || can('product-series.view') || can('product-model.view') || can('product-unit.view') || can('discount.view') || can('reward.view')) && (
                    <div className="space-y-2">
                        <div className="text-xs">
                            Data
                        </div>
                        <ul className="space-y-6">
                            {(can('brand.view') || can('series.view')) && (
                                <li>
                                    <Disclosure>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex items-center justify-between w-full">
                                                    <div className="flex items-center space-x-4">
                                                        <div className={`${(open || url === '/brand' || url === '/brand/series') ? 'bg-neutral-900 ring-1 ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                                            <Trademark className="w-6 h-6" />
                                                        </div>
                                                        <div>
                                                            Brand
                                                        </div>
                                                    </div>
                                                    <div className={`${open ? 'rotate-90' : ''} transition-transform p-[2px] border rounded-full border-neutral-300 bg-neutral-200/50`}>
                                                        <ChevronRight className="w-4 h-4" stroke={1} />
                                                    </div>
                                                </Disclosure.Button>
                                                <AnimatePresence mode="wait">
                                                    {open && (
                                                        <motion.div initial={{ height: 0, marginTop: 0, opacity: 0 }} animate={{ height: 'auto', marginTop: '1rem', opacity: 1 }} exit={{ height: 0, marginTop: 0, opacity: 0 }} transition={{ type: "spring", damping: 20 }} className="overflow-hidden">
                                                            <Disclosure.Panel static>
                                                                <ul className="space-y-4">
                                                                    {can('brand.view') && (
                                                                        <li>
                                                                            <Link to="/brand" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/brand' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/brand' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Brand</span>
                                                                                {url === '/brand' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {can('series.view') && (
                                                                        <li>
                                                                            <Link to="/brand/series" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/brand/series' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/brand/series' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Series</span>
                                                                                {url === '/brand/series' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </Disclosure.Panel>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </>
                                        )}
                                    </Disclosure>
                                </li>
                            )}

                            {(can('attribute.view') || can('attribute-group.view')) && (
                                <li>
                                    <Disclosure>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex items-center justify-between w-full">
                                                    <div className="flex items-center space-x-4">
                                                        <div className={`${(open || url === '/attribute/group' || url === '/attribute') ? 'bg-neutral-900 ring-1 ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                                            <AB className="w-6 h-6" />
                                                        </div>
                                                        <div>
                                                            Attribute
                                                        </div>
                                                    </div>
                                                    <div className={`${open ? 'rotate-90' : ''} transition-transform p-[2px] border rounded-full border-neutral-300 bg-neutral-200/50`}>
                                                        <ChevronRight className="w-4 h-4" stroke={1} />
                                                    </div>
                                                </Disclosure.Button>
                                                <AnimatePresence mode="wait">
                                                    {open && (
                                                        <motion.div initial={{ height: 0, marginTop: 0, opacity: 0 }} animate={{ height: 'auto', marginTop: '1rem', opacity: 1 }} exit={{ height: 0, marginTop: 0, opacity: 0 }} transition={{ type: "spring", damping: 20 }} className="overflow-hidden">
                                                            <Disclosure.Panel static>
                                                                <ul className="space-y-4">
                                                                    {can('attribute-group.view') && (
                                                                        <li>
                                                                            <Link to="/attribute/group" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/attribute/group' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/attribute/group' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>Group</span>
                                                                                {url === '/attribute/group' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {can('attribute.view') && (
                                                                        <li>
                                                                            <Link to="/attribute" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/attribute' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/attribute' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>List</span>
                                                                                {url === '/attribute' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </Disclosure.Panel>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </>
                                        )}
                                    </Disclosure>
                                </li>
                            )}

                            {(can('product-need.view') || can('product-family.view') || can('product-class.view') || can('product-line.view') || can('product-type.view') || can('product-brand.view') || can('product-series.view') || can('product-model.view') || can('product-unit.view') || can('product-unit-exclusivity.view')) && (
                                <li>
                                    <Disclosure>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex items-center justify-between w-full">
                                                    <div className="flex items-center space-x-4">
                                                        <div className={`${(open || url === '/product/need' || url === '/product/family' || url === '/product/class' || url === '/product/line' || url === '/product/type' || url === '/product/brand' || url === '/product/series' || url === '/product/model' || url === '/product/unit') ? 'bg-neutral-900 ring-1 ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                                            <Box className="w-6 h-6" />
                                                        </div>
                                                        <div>
                                                            Product
                                                        </div>
                                                    </div>
                                                    <div className={`${open ? 'rotate-90' : ''} transition-transform p-[2px] border rounded-full border-neutral-300 bg-neutral-200/50`}>
                                                        <ChevronRight className="w-4 h-4" stroke={1} />
                                                    </div>
                                                </Disclosure.Button>
                                                <AnimatePresence mode="wait">
                                                    {open && (
                                                        <motion.div initial={{ height: 0, marginTop: 0, opacity: 0 }} animate={{ height: 'auto', marginTop: '1rem', opacity: 1 }} exit={{ height: 0, marginTop: 0, opacity: 0 }} transition={{ type: "spring", damping: 20 }} className="overflow-hidden">
                                                            <Disclosure.Panel static>
                                                                <ul className="space-y-4">
                                                                    {(can('product-need.view') && configuration('hierarchy').find((row) => row.key === 'product-need').value) && (
                                                                        <li>
                                                                            <Link to="/product/need" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/need' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/need' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Need
                                                                                </span>
                                                                                {url === '/product/need' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {(can('product-family.view') && configuration('hierarchy').find((row) => row.key === 'product-family').value) && (
                                                                        <li>
                                                                            <Link to="/product/family" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/family' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/family' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Family
                                                                                </span>
                                                                                {url === '/product/family' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {(can('product-class.view') && configuration('hierarchy').find((row) => row.key === 'product-class').value) && (
                                                                        <li>
                                                                            <Link to="/product/class" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/class' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/class' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Class
                                                                                </span>
                                                                                {url === '/product/class' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {(can('product-line.view') && configuration('hierarchy').find((row) => row.key === 'product-line').value) && (
                                                                        <li>
                                                                            <Link to="/product/line" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/line' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/line' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Line
                                                                                </span>
                                                                                {url === '/product/line' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {(can('product-type.view') && configuration('hierarchy').find((row) => row.key === 'product-type').value) && (
                                                                        <li>
                                                                            <Link to="/product/type" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/type' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/type' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Type
                                                                                </span>
                                                                                {url === '/product/type' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {(can('product-brand.view') && configuration('hierarchy').find((row) => row.key === 'product-brand').value) && (
                                                                        <li>
                                                                            <Link to="/product/brand" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/brand' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/brand' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Brand
                                                                                </span>
                                                                                {url === '/product/brand' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {(can('product-series.view') && configuration('hierarchy').find((row) => row.key === 'product-series').value) && (
                                                                        <li>
                                                                            <Link to="/product/series" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/series' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/series' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Series
                                                                                </span>
                                                                                {url === '/product/series' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {(can('product-model.view') && configuration('hierarchy').find((row) => row.key === 'product-model').value) && (
                                                                        <li>
                                                                            <Link to="/product/model" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/model' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/model' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Model
                                                                                </span>
                                                                                {url === '/product/model' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {(can('product-unit.view') && configuration('hierarchy').find((row) => row.key === 'product-unit').value) && (
                                                                        <li>
                                                                            <Link to="/product/unit" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/unit' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/unit' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Unit
                                                                                </span>
                                                                                {url === '/product/unit' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                    {/* {(can('product-map.view') && configuration('site') === 'hq') && (
                                                                        <li>
                                                                            <Link to="/product/map" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center ml-3 text-xs group">
                                                                                <div className={`${url === '/product/map' ? 'bg-neutral-800 border-neutral-800' : 'bg-neutral-400/50 border-neutral-400'} transition w-4 h-4 border rounded-full group-hover:bg-neutral-800 group-hover:border-neutral-800`}></div>
                                                                                <span className={`${url === '/product/map' ? 'text-neutral-800' : 'text-neutral-500'} ml-7 group-hover:text-neutral-800 transition`}>
                                                                                    Branch Map
                                                                                </span>
                                                                                {url === '/product/map' && (<div className="flex-grow ml-4 border-t border-neutral-900"></div>)}
                                                                            </Link>
                                                                        </li>
                                                                    )} */}
                                                                </ul>
                                                            </Disclosure.Panel>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </>
                                        )}
                                    </Disclosure>
                                </li>
                            )}

                            {(can('product-unit-exclusivity.view') && configuration('site') === 'hq') && (
                                <li>
                                    <Link to="/exclusivity" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/exclusivity' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <PackageExclusive className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Exclusivity
                                        </div>
                                        {url === '/exclusivity' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('discount.view') && (
                                <li>
                                    <Link to="/discount" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/discount' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <Percentage className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Discount
                                        </div>
                                        {url === '/discount' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('reward.view') && (
                                <li>
                                    <Link to="/reward" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/reward' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <Reward className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Reward
                                        </div>
                                        {url === '/reward' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}

                {(can('product-unit.create') || can('discount.create')) && (
                    <div className="space-y-2">
                        <div className="text-xs">
                            Wizard
                        </div>
                        <ul className="space-y-6">
                            {can('product-unit.create') && (
                                <li>
                                    <Link to="/product/wizard" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/product/wizard' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" stroke-width={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M12.5 21h-7.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5"></path>
                                                <path d="M16 19h6"></path>
                                                <path d="M19 16v6"></path>
                                            </svg>
                                        </div>
                                        <div>
                                            New Product
                                        </div>
                                        {url === '/product/wizard' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}

                            {can('discount.create') && (
                                <li>
                                    <Link to="/discount/apply" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/discount/apply' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <Percentage className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Apply Disc.
                                        </div>
                                        {url === '/discount/apply' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}

                {/* <div className="space-y-2">
                    <div className="text-xs">
                        Misc
                    </div>
                    <ul className="space-y-6">
                        {true && (
                            <li>
                                <Link to="/settings" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                    <div className={`${url === '/settings' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                        <Settings className="w-6 h-6" />
                                    </div>
                                    <div>
                                        Settings
                                    </div>
                                    {url === '/settings' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                </Link>
                            </li>
                        )}
                    </ul>
                </div> */}
            </nav>
        </motion.div>
    )
}

export default Sidebar